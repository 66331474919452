import React from "react"
import SEO from "../components/seo"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from 'gatsby-image';

import HireUs from "../components/HireUs/index";
import Careers from '../components/Careers/index';
import Carousel from "../components/Carousel";
import Button from "../components/Button";
import Results from "../components/Results";
import NextStep from "../components/NextStep";
import Services from "../components/Services";
import OurPartners from "../components/OurPartners";

const IndexPage = ({ data }) => (
  <>
    <SEO title="The Printman Publishers & Printers Peshawar Pakistan" 
          lang='en'
    />
    <div className="flex flex-col min-h-full mx-auto">
      {/* Main home landing container */}

      <Landing></Landing>
      {/* <OurPartners></OurPartners> */}
      <WhoWeAre></WhoWeAre>
      <Services></Services>

      {/* Paths we have travelled container */}
      
      {/* <Carousel></Carousel> */}

      {/* <Results></Results> */}

      {/* Hire Us */}
      {/* <section className="container mx-auto p-5">
        <HireUs />
        <Careers />
      </section> */}

      <NextStep></NextStep>
    </div>
  </>
)

export default IndexPage


const Landing =() => {

  const data = useStaticQuery(graphql`
        query LandingImageQuery {
            monssStudio: file(relativePath: { eq: "printman_factory.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000, maxHeight: 1335, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
                
            }
        }
    `)

  return (
    <div className="container mx-auto md:pt-20 pb-10 p-5">
      <h1 className="text-4xl">
        Welcome to The Printman Printers & Publishers
      </h1>
      <div className="flex flex-col md:flex-row justify-between pt-5 md:pt-10 lg:pt-20">
        <div className="flex-1" >
          <Img
          alt="Monss Studio Calgary"
          fadeIn
          fluid={data.monssStudio.childImageSharp.fluid}/></div>

        <div className="w-full md:w-2/5 xl:w-1/3 text-lg lg:text-2xl leading-10 text-black text-opacity-75 mt-5 md:ml-12 md:mt-0">

          <h2>
            Printman Publishers and Printers is Pakistan's premium full service digital & commercial printing press in Peshawar with over 20 years of experience. 
            <br/>
            <br/>
            Our printing press specializes in offset printing, digital printing, bindery, packaging, and distribution at our Peshawar printing press.
          </h2>

        <div className="mt-4 md:mt-10">
            <Link to="/about/">
              <Button text="About us" />
            </Link>
        </div>
        </div>
      </div>
    </div>
  )
}



const WhoWeAre = () => {
  return (
    <section className="container mx-auto pb-20 relative flex lg:block">
      <div className="my-auto">
        <p className="text-center text-sm opacity-50 m-5 capitalize">About Us</p>
        <div className="w-2/3 m-auto">
          <h2 className="text-left md:text-center md:leading-relaxed text-xl md:text-2xl">
            Established in 1996, our press, The Printman Printers & Publishers is a printing company in Khyber Pakhtunkhwa offering the full range of print services to a variety of clients operating across many different industries and sectors. We are a one-stop shop for all your printing requirements; our customized solutions are tailored specifically to your individual needs, ensuring the best results every time throughout the pre-press, printing, finishing and binding process.
        </h2>
        </div>
      </div>
        
        <p className="absolute bottom-0 md:bottom-10 left-0 h-40 w-3  bg-black"></p>
    </section>
  )
}

