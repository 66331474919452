import { Link } from 'gatsby'
import React from 'react'

interface Props {
    
}

const NextStep = (props: Props) => {
    return (
        <section className="bg-white h-screen/3 text-black flex">
            
            <div className="m-auto container p-5">
                <p className="text-sm md:text-base opacity-50">Find out how we can help your business</p>
                <p className="text-4xl md:text-7xl">Contact us today for a free quote!</p>
                <Link className="md:text-xl underline" to="/contact/">Let's talk</Link>
            </div>
        </section>
    )
}

export default NextStep
