import React from 'react';

const ServiceArray = [
    'Print Marketing',
    'Digital Printing',
    'Offset Printing',
    'Brochures',
    'Stationary Printing',
    'Image Setting',
    'CTP Machine'
]

const ProductArray = [
    'Binding',
    'Stationary Printing',
    'Envelopes',
    'Letterheads',
    'Banners',
    'Signage',
    'Vehicle Wraps'
]

const Services = () => {

    return (
        <>
            <section className="bg-monss py-10">
                <div className="container mx-auto p-5 flex flex-wrap text-white space-y-10 md:space-y-0 md:gap-10" >
                    <div className="w-full sm:w-1/2 lg:w-2/5 sm:px-25 lg:px-5">
                        <h3 className="py-3 uppercase">Services</h3>
                        <ul className="divide-y divide-white divide-opacity-70">
                            {ServiceArray.map(d => {
                                return (
                                    <li className="text-xl py-3" key={d} >{d}</li>
                                )
                            })}
                        </ul>
                    </div>

                    <div className="w-full sm:w-1/2 lg:w-2/5 sm:px-25 lg:px-5">
                        <h3 className="py-3 text-black uppercase">_</h3>
                        <ul className="divide-y divide-white divide-opacity-70">
                            {ProductArray.map(d => {
                                return (
                                    <li className="text-xl py-3" key={d} >{d}</li>
                                )
                            })}
                        </ul>
                    </div>

                    <div className="w-1/4">
                        
                    </div>
                    
                </div>
            </section>
        </>
    )
}


export default Services;